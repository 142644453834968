.imagecaption-popup {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.22);
  background-color: #fff;
  border-radius: 5px;
  max-width: 300px;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 10px;
  transform: translateX(-50%);
}
.imagecaption-popup__beacon {
  border-top: 10px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: -1px;
  margin-left: -9px;
}

.widget-photosrow {
  padding: 0 0 35px;
  background-color: #1A7DD7;
  position: relative;
}
.widget-photosrow--blue {
  background-color: #1A7DD7;
}
.widget-photosrow--green {
  background-color: #289C5C;
}
.widget-photosrow--red {
  background-color: #ED4C10;
}
.widget-photosrow.ps-active-x {
  padding-bottom: 40px;
}
.widget-photosrow .ps-scrollbar-x-rail {
  top: 20px;
  max-width: 920px;
  margin: 0 auto;
}
.widget-photosrow .inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 980px;
}
@media (max-width: 600px) {
  .widget-photosrow .inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.widget-photosrow ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  min-height: 250px;
}
.widget-photosrow ul > li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 35px 20px 0 0;
}
.widget-photosrow .image {
  min-width: 1px;
  display: inline-block;
}
.widget-photosrow .image__caption {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
}
.widget-photosrow .image > img {
  height: 100%;
  width: auto;
  vertical-align: bottom;
}
@media (max-width: 980px) {
  .widget-photosrow .ps-scrollbar-x-rail {
    max-width: calc(100% - 60px);
  }
}
@media (max-width: 600px) {
  .widget-photosrow .ps-scrollbar-x-rail {
    max-width: calc(100% - 40px);
  }
}