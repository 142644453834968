.myprofile-sidetab {
  position: fixed;
  right: 0;
  top: 50%;
}
.myprofile-sidetab a {
  position: relative;
  top: 0;
  right: 0;
  transform-origin: 100% 100%;
  transform: rotate(-90deg) translateX(50%);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  height: clamp(30px, 4.8vw, 48px);
  font-weight: 600;
  line-height: 30px;
  font-size: clamp(14px, 1.5vw, 15px);
  padding: 0 clamp(12px, 2vw, 20px);
  color: #fff;
  background-color: #000;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}
.myprofile-sidetab a i {
  margin-left: 15px;
}

.auth-menu {
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;
}
@media (max-width: 800px) {
  html.mobilelayout .auth-menu {
    margin-right: 0;
  }
}
html.donatepage .auth-menu {
  display: none;
}
@media (max-width: 420px) {
  .auth-menu__loggedin > .far, .auth-menu__login > .far {
    padding-right: 0;
  }
  .auth-menu__loggedin > .title, .auth-menu__login > .title {
    display: none;
  }
}
.auth-menu i {
  display: inline-block;
  padding-right: 10px;
  font-size: 19px;
  position: relative;
  top: 2px;
}
.auth-menu__loggedin {
  display: none;
  position: relative;
}
.auth-menu__loggedin > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 10;
  height: 0;
  opacity: 0;
  transition: height 0.2s, opacity 0.2s;
  display: block;
  background-color: #1B2453;
  top: 100%;
  left: -20px;
  position: absolute;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
}
html.donatepage .auth-menu__loggedin > ul {
  background-color: #DFB132;
}
.auth-menu__loggedin > ul li {
  height: 40px;
  line-height: 40px;
}
.auth-menu__loggedin > ul li:last-child {
  margin-bottom: 5px;
}
.auth-menu__loggedin > ul a {
  color: #fff;
  display: block;
  padding: 0 20px;
  text-decoration: none;
}
.auth-menu__loggedin > ul a:hover {
  text-decoration: underline;
  text-shadow: 0 0 1px #fff;
}
.auth-menu__loggedin:focus-within > ul {
  height: 85px;
  opacity: 1;
}
.auth-menu__loggedin:hover > ul, .auth-menu__loggedin:focus > ul {
  height: 85px;
  opacity: 1;
}
html.wh-wrdauth--isloggedin .auth-menu__login {
  display: none;
}
html.wh-wrdauth--isloggedin .auth-menu__loggedin {
  display: block;
}

html.loginpage main {
  padding-top: 0;
}

.loginpage-content {
  padding-top: 30px;
}

.loginpage__previewbutton.widget-cta__button {
  min-width: 160px;
}

.loginpage__myprofile {
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
}
.loginpage__myprofile__name {
  flex: 1;
  margin-right: 15px;
  background-color: #e7e9e4;
  padding: 10px 15px;
  display: flex;
  font-weight: bold;
  align-items: center;
  border-radius: 2px;
}
.loginpage__myprofile__name span {
  display: inline-block;
}
.loginpage__myprofile__button.widget-cta__button {
  min-width: 160px;
  flex: none;
}

.wh-wrdauth-form {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
  padding-top: 40px;
  padding-bottom: 20px;
}
@media (max-width: 600px) {
  .wh-wrdauth-form {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.searchpersons {
  margin-top: 50px;
  margin-bottom: 20px;
}

.searchpersonlist {
  margin-top: 10px;
}
.searchpersonlist__add > .widget-cta__button {
  min-width: 160px;
}
.searchpersonlist__row {
  display: flex;
  margin-bottom: 20px;
  align-items: stretch;
}
.searchpersonlist__row__person {
  flex: 1;
  font-weight: bold;
  background-color: #e7e9e4;
  padding: 14px 15px 10px;
  line-height: 18px;
  border-radius: 2px 0 0 2px;
}
.searchpersonlist__row a {
  flex: none;
  margin-left: 12px;
  min-width: 160px;
}
.searchpersonlist__row a.widget-cta__button--text {
  margin-top: 10px;
}
.searchpersonlist__row__delete {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  margin-left: 20px;
  font-size: 16px;
  background-color: #e7e9e4;
  display: inline-block;
  text-decoration: none;
  margin-left: 0;
  padding: 0 12px;
  width: 40px;
  border-radius: 0 2px 2px 0;
}
@media (max-width: 600px) {
  .searchpersonlist__row {
    flex-wrap: wrap;
  }
  .searchpersonlist__row__person {
    flex: none;
    width: calc(100% - 40px);
  }
  .searchpersonlist__row__delete + a {
    margin-left: 0;
  }
  .searchpersonlist__row a {
    margin-top: 5px;
  }
  .searchpersonlist__row a.widget-cta__button--text {
    margin-top: 15px;
  }
}

.orderoverview table + table {
  margin-top: 20px;
}
.orderoverview th {
  padding-right: 10px;
}
.orderoverview th, .orderoverview td {
  vertical-align: top;
  text-align: left;
}