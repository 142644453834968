html.registrationpage main {
  padding-top: 0;
}
html.registrationpage .searchpersons_options {
  margin-top: 30px;
}

#registrationform {
  padding-top: 40px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
}
@media (max-width: 600px) {
  #registrationform {
    padding-left: 20px;
    padding-right: 20px;
  }
}
#registrationform .wh-form__fieldgroup.publicaccess > .wh-form__label, #registrationform .wh-form__fieldgroup.restrictedaccess > .wh-form__label, #registrationform .wh-form__fieldgroup.noaccess > .wh-form__label {
  position: relative;
  padding-left: 32px;
  min-height: 20px;
}
#registrationform .wh-form__fieldgroup.publicaccess > .wh-form__label::before, #registrationform .wh-form__fieldgroup.restrictedaccess > .wh-form__label::before, #registrationform .wh-form__fieldgroup.noaccess > .wh-form__label::before {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #289C5C;
  font-size: 20px;
}
#registrationform .wh-form__fieldgroup.publicaccess > .wh-form__label::before {
  content: "\f058";
  color: #289C5C;
}
#registrationform .wh-form__fieldgroup.restrictedaccess > .wh-form__label::before {
  content: "\f058";
  color: #FA6400;
}
#registrationform .wh-form__fieldgroup.noaccess > .wh-form__label::before {
  content: "\f05e";
  color: #6236FF;
}
#registrationform .wh-form__fieldgroup.noaccess > .wh-form__label[for="searchforfamilyform-wrd_contact_address.country"]::before {
  content: "\f058";
  color: #289C5C;
}
#registrationform .wh-form__fieldgroup--array.labelabove > .wh-form__fields {
  padding-left: 32px;
}
#registrationform .wh-form__fieldgroup--array .wh-form__fieldgroup > .wh-form__label {
  padding-left: 0;
}
#registrationform .wh-form__fieldgroup--array .wh-form__fieldgroup > .wh-form__label::before {
  display: none;
}

.registration-legenda {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 600px) {
  .registration-legenda {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.registration-legenda ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 150%;
}
.registration-legenda ul li {
  margin-top: 10px;
  position: relative;
  padding-left: 32px;
}
.registration-legenda ul .icon {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 5px;
}
.registration-legenda ul .publicaccess .icon {
  color: #289C5C;
}
.registration-legenda ul .restrictedaccess .icon {
  color: #FA6400;
}
.registration-legenda ul .noaccess .icon {
  color: #6236FF;
}