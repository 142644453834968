/* Note: Styling also used by submit button on donate page */

/*
color_blue:      #283477;
color_lightblue: #1A7DD7;
color_green:     #026A16;
color_lightgreen:#289C5C;
color_red:       #CA243A;
color_lightred:  #ED4C10;
*/

.widget-cta--center
{
  text-align: center;
}
.widget-cta--right
{
  text-align: right;
}

.widget-cta__button
{
  text-decoration: none;
  display: inline-block;
  position: relative;
  background-color: #283477;
  border-radius: 2px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  text-align: left;
  height: 48px;
  padding: 10px 17px;
  color: #fff;
  min-width: 255px;
  max-width: 100%;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.27);
  border-radius: 2px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  margin: 0;
  border: 0 none;
}
.widget-cta__button--big
{
  border-radius: 3px;
  font: 700 30px/50px 'Faustina', serif, Arial;
  height: 56px;
  padding: 0 20px;
}
.widget-cta__button .title
{
  display: block;
  vertical-align: top;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
}
.widget-cta__button:hover
{
  background-color: #1A7DD7;
}
.widget-cta__button .icon
{
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -11px;
  font-size: 24px;
}
.widget-cta__button .icon + .title
{
  padding-right: 35px;
}
.widget-cta__button .icon--small
{
  font-size: inherit;
  margin-top: -8px;
}
.widget-cta__button--outline
{
  color: #283477;
  border: 2px solid #283477;
  background-color: #fff;
  box-shadow: none;
  transition: color 0.3s, border-color 0.3s;
}
.widget-cta__button--outline:hover
{
  background-color: #fff;
  color: #1A7DD7;
  border: 2px solid #1A7DD7;
}

.widget-cta__button--text
{
  color: #283477;
  background-color: transparent;
  transition: color 0.3s;
  box-shadow: none;
  min-width: 0;
  padding: 5px 0;
}
.widget-cta__button--text:hover
{
  background-color: transparent;
  color: #1A7DD7;
}
.widget-cta__button--text .icon
{
  right: auto;
  left: 0;
  top: 50%;
  margin-top: -11px;
  font-size: 24px;
}
.widget-cta__button--text .icon + .title
{
  padding: 5px 0 0 30px;
}

.widget-cta__button--green
{
  background-color: #026A16;
}
.widget-cta__button--green:hover
{
  background-color: #289C5C;
}
.widget-cta__button--green.widget-cta__button--outline
{
  background-color: #fff;
  color: #026A16;
  border-color: #026A16;
}
.widget-cta__button--green.widget-cta__button--outline:hover
{
  background-color: #fff;
  color: #289C5C;
  border-color: #289C5C;
}
.widget-cta__button--green.widget-cta__button--text
{
  background-color: transparent;
  color: #026A16;
}
.widget-cta__button--green.widget-cta__button--text:hover
{
  background-color: transparent;
  color: #289C5C;
}

.widget-cta__button--red
{
  background-color: #CA243A;
}
.widget-cta__button--red:hover
{
  background-color: #ED4C10;
}
.widget-cta__button--red.widget-cta__button--outline
{
  background-color: #fff;
  color: #CA243A;
  border-color: #CA243A;
}
.widget-cta__button--red.widget-cta__button--outline:hover
{
  background-color: #fff;
  color: #ED4C10;
  border-color: #ED4C10;
}
.widget-cta__button--red.widget-cta__button--text
{
  background-color: transparent;
  color: #CA243A;
}
.widget-cta__button--red.widget-cta__button--text:hover
{
  background-color: transparent;
  color: #ED4C10;
}


/* If button inside pageimage default/hover background color are switched */
.pageimage .widget-cta__button--solid
{
  background-color: #1A7DD7;
}
.pageimage .widget-cta__button--solid:hover
{
  background-color: #283477;
}
.pageimage .widget-cta__button--solid.widget-cta__button--green
{
  background-color: #289C5C;
}
.pageimage .widget-cta__button--solid.widget-cta__button--green:hover
{
  background-color: #026A16;
}
.pageimage .widget-cta__button--solid.widget-cta__button--red
{
  background-color: #ED4C10;
}
.pageimage .widget-cta__button--solid.widget-cta__button--red:hover
{
  background-color: #CA243A;
}
