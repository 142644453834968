#paymentresult-message h2 {
  margin-right: 30px;
}
#paymentresult-message p {
  margin-top: 15px;
  margin-bottom: 0;
}

.smallpaymentform {
  box-shadow: 3px 4px 13px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  background-color: #fff;
  max-width: calc(50% - 15px);
}
@media (max-width: 1100px) {
  .smallpaymentform {
    max-width: 100%;
  }
}
.smallpaymentform h2 {
  margin-bottom: 30px;
}
.smallpaymentform h3 {
  margin-bottom: 25px;
}
.smallpaymentform__naw {
  padding: 25px;
}
.smallpaymentform__header {
  padding: 25px 25px 20px;
}
.smallpaymentform__header.wh-form__fieldgroup {
  display: block;
  margin-bottom: 0 !important;
}
@media (max-width: 600px) {
  .smallpaymentform {
    margin-left: -20px;
    margin-right: -20px;
    min-width: 100vw;
    border-radius: 0;
  }
  .smallpaymentform__header {
    padding: 10px;
  }
}
@media (max-width: 550px) {
  .smallpaymentform__header .amountoption--other .wh-form__fieldgroup {
    flex-flow: row;
  }
}
.smallpaymentform__header, .smallpaymentform__footer {
  background-color: rgba(122, 210, 185, 0.5);
}
.smallpaymentform__footer {
  padding: 35px 25px 0;
}
.smallpaymentform__footer > .wh-form__fieldgroup > .wh-form__label {
  display: none;
}
.smallpaymentform__footer > .wh-form__fieldgroup > .wh-form__fields {
  max-width: 100% !important;
}
.smallpaymentform__footer > .wh-form__fieldgroup .wh-form__optiondata .wh-form__optionlabel {
  width: 125px;
}
.smallpaymentform__footer > .wh-form__fieldgroup .wh-form__optiondata .wh-form__subfield {
  padding-left: 0;
  width: calc(100% - 145px);
}
.smallpaymentform__footer > .wh-form__fieldgroup .wh-form__optiondata .wh-form__subfieldlabel {
  display: none;
}
@media (max-width: 1100px) {
  .smallpaymentform__footer > .wh-form__fieldgroup .wh-form__optiondata .wh-form__optionlabel {
    width: 175px;
  }
  .smallpaymentform__footer > .wh-form__fieldgroup .wh-form__optiondata .wh-form__subfield {
    width: calc(100% - 195px);
  }
}
@media (max-width: 550px) {
  .smallpaymentform__footer > .wh-form__fieldgroup .wh-form__optiondata .wh-form__optionlabel {
    width: 95px;
  }
  .smallpaymentform__footer > .wh-form__fieldgroup .wh-form__optiondata .wh-form__subfield {
    width: calc(100% - 115px);
  }
}
.smallpaymentform .widget-cta .widget-cta__button--green {
  background-color: #289C5C;
  min-width: calc(100% - 145px);
}
.smallpaymentform .widget-cta .widget-cta__button--green:hover {
  background-color: #026A16;
}
@media (max-width: 1100px) {
  .smallpaymentform .widget-cta .widget-cta__button--green {
    min-width: 0;
  }
}
.smallpaymentform .amountoptions {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.smallpaymentform .amountoptions .amountoption {
  display: inline-block;
}
.smallpaymentform .amountoptions .amountoption + li {
  margin-left: 10px;
}
.smallpaymentform .amountoptions .amountoption__title {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border: 5px solid transparent;
  border-bottom-width: 8px;
  transition: border-color 0.2s;
  border-radius: 3px 3px 0 0;
}
.smallpaymentform .amountoptions .amountoption__title span {
  display: block;
  height: 48px;
  background-color: #000;
  border-radius: 2px;
  padding: 3px 15px;
  font: bold 28px/135% "Faustina", serif, Arial;
}
.smallpaymentform .amountoptions .amountoption--other .amountoption__title span {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
.smallpaymentform .amountoptions .amountoption__description {
  opacity: 0;
  transition: opacity 0.4s;
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  padding: 8px 10px;
  border-radius: 3px;
  font-size: 15px;
  top: 60px;
  background-color: #FFD152;
}
.smallpaymentform .amountoptions .amountoption__description > *:last-child {
  margin-bottom: 0;
}
.smallpaymentform .amountoptions .amountoption__description .wh-form__label {
  max-width: 135px !important;
  min-width: 135px !important;
  text-align: right;
  padding-right: 15px;
  font: normal 28px/38px "Faustina", serif, Arial;
  color: #283477;
}
@media (max-width: 1100px) {
  .smallpaymentform .amountoptions .amountoption__description .wh-form__label {
    max-width: 30px !important;
    min-width: 30px !important;
    text-align: left;
  }
  .smallpaymentform .amountoptions .amountoption__description .wh-form__fields {
    max-width: 200px !important;
  }
}
.smallpaymentform .amountoptions .amountoption__description .wh-form__fieldgroup {
  padding-bottom: 8px;
}
.smallpaymentform .amountoptions .amountoption__description .wh-form__fields {
  max-width: 150px;
}
@media (max-width: 1000px) {
  .smallpaymentform .amountoptions .amountoption + li {
    margin-left: 1vw;
  }
}
@media (max-width: 600px) {
  .smallpaymentform .amountoptions .amountoption__title span {
    padding: 3px 2.5vw;
  }
}
@media (max-width: 528px) {
  .smallpaymentform .amountoptions .amountoption__title span {
    font-size: 5.3vw;
  }
}
@media (max-width: 480px) {
  .smallpaymentform .amountoptions .amountoption__title span {
    height: 10vw;
  }
}
@media (max-width: 462px) {
  .smallpaymentform .amountoptions .amountoption__description {
    top: 13vw;
  }
}
@media (max-width: 320px) {
  .smallpaymentform .amountoptions .amountoption + li {
    margin-left: 3px;
  }
  .smallpaymentform .amountoptions .amountoption__title span {
    font-size: 17px;
    height: 32px;
    padding: 3px 8px;
  }
  .smallpaymentform .amountoptions .amountoption__description {
    top: 41px;
  }
}
.smallpaymentform .amountoptions .amountoption input:checked + .amountoption__title {
  border-color: #FFD152;
}
.smallpaymentform .amountoptions .amountoption input:checked + .amountoption__title + .amountoption__description {
  opacity: 1;
  z-index: 0;
}
.smallpaymentform .amountoptions .amountoption .amountoption__description > p {
  margin-bottom: 15px;
}