html.dnakitpage main {
  position: relative;
  padding-top: 60px;
}
html.dnakitpage .pageimage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  z-index: -1;
  height: auto;
  max-height: none;
}
html.dnakitpage .pageimage .content {
  display: none;
}
html.dnakitpage .dnakitpage-wrapper {
  display: flex;
}
html.dnakitpage .dnakitpage-wrapper > * {
  flex: 1 1 100%;
  justify-content: space-between;
  max-width: 50%;
}
html.dnakitpage .dnakitpage-wrapper .dnakitintro {
  order: 2;
  margin-left: 15px;
  color: #fff;
  text-shadow: 0 0 2px rgb(0, 0, 0);
}
html.dnakitpage .dnakitpage-wrapper .dnakitform {
  margin-right: 15px;
}
html.dnakitpage .dnakitpage-wrapper h1 {
  text-align: left;
  padding-right: 15px;
  margin: 0;
  color: #fff;
  padding: 20px 0;
}
@media (max-width: 1100px) {
  html.dnakitpage .dnakitpage-wrapper {
    display: block;
  }
  html.dnakitpage .dnakitpage-wrapper > * {
    max-width: 100%;
  }
  html.dnakitpage .dnakitpage-wrapper .dnakitkitintro {
    margin-top: -60px;
    margin-left: 0;
  }
  html.dnakitpage .dnakitpage-wrapper .dnakitform {
    margin-right: 0;
  }
  html.dnakitpage .dnakitpage-wrapper h1 {
    padding-bottom: 30px;
  }
}
html.dnakitpage article > * {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
}
@media (max-width: 600px) {
  html.dnakitpage article > * {
    padding-left: 20px;
    padding-right: 20px;
  }
}