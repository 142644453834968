.widget-photoalbum h2 {
  margin-bottom: 20px;
}
.widget-photoalbum__container {
  position: relative;
  /*
  16:9 aspect:
  height = 56.25% of total width

  right width 3x squares:
  56.25% / 3 = 18.75%;

  preview width: 100 - 18.75%;
  */
}
.widget-photoalbum__container::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-photoalbum__container__preview {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 81.25%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.widget-photoalbum__container__preview::after {
  content: "";
  display: block;
  position: absolute;
  right: -2px;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #fff;
}
.widget-photoalbum__container__preview__title {
  color: #fff;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 15px;
  line-height: 22px;
  padding: 15px 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  min-height: 50%;
  max-height: 100%;
}
.widget-photoalbum__container__rightcol {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 18.75%;
  overflow: hidden;
}
.widget-photoalbum__container__rightcol__thumb {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.widget-photoalbum__container__rightcol__thumb::after {
  vertical-align: bottom;
  content: "";
  display: block;
  padding-top: 100%;
}
.widget-photoalbum__container__rightcol__thumb + .widget-photoalbum__container__rightcol__thumb::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -2px;
  width: 100%;
  height: 4px;
  background-color: #fff;
}
.widget-photoalbum__count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
  height: 33.3333%;
  text-align: center;
  width: 18.75%;
  color: #283477;
  cursor: pointer;
}
.widget-photoalbum__count > span {
  width: 74px;
  height: 74px;
  margin-top: 3px;
  display: inline-block;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  font: bold 40px/70px "Faustina", serif, Arial;
}
.widget-photoalbum.fullwidth {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #F3F3F1;
}
.widget-photoalbum.fullwidth .widget-photoalbum__container__preview::after,
.widget-photoalbum.fullwidth .widget-photoalbum__container__rightcol__thumb + .widget-photoalbum__container__rightcol__thumb::before {
  background-color: #F3F3F1;
}
@media (max-width: 600px) {
  .widget-photoalbum__container__preview__title {
    font-size: 13px;
    line-height: 16px;
    padding: 10px;
  }
  .widget-photoalbum__count > span {
    width: 11.65vw;
    height: 11.65vw;
    font-size: 6.65vw;
    line-height: 11vw;
  }
}

/* overlay */
.photoalbum-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #070F38;
  z-index: 1000;
  color: #fff;
}
.photoalbum-overlay h2 {
  color: #fff;
}
.photoalbum-overlay__title {
  margin: 25px 0 30px;
  padding: 0 80px;
  color: #fff;
  text-align: center;
}
.photoalbum-overlay .counter {
  position: absolute;
  top: 26px;
  left: 30px;
}
.photoalbum-overlay .close {
  cursor: pointer;
  position: absolute;
  font-size: 30px;
  right: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  z-index: 1;
}
.photoalbum-overlay .next, .photoalbum-overlay .previous {
  position: absolute;
  top: 80px; /* keep space for counter and close btn */
  bottom: 80px;
  width: 60px;
  cursor: pointer;
  font-size: 54px;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
}
.photoalbum-overlay .next.disabled, .photoalbum-overlay .previous.disabled {
  cursor: default;
  opacity: 0.3;
}
.photoalbum-overlay .next {
  right: 0;
  justify-content: flex-start;
}
.photoalbum-overlay .next:not(.disabled) > *:hover {
  transform: translateX(5px);
}
.photoalbum-overlay .previous {
  left: 0;
  justify-content: flex-end;
}
.photoalbum-overlay .previous:not(.disabled) > *:hover {
  transform: translateX(-5px);
}
.photoalbum-overlay .slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.photoalbum-overlay .image {
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transition: transform 500ms cubic-bezier(0.5, 0, 0, 1), width 200ms, height 200ms;
}
.photoalbum-overlay .image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s;
}
.photoalbum-overlay .image__sizer > span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  font-size: 50px;
  opacity: 0.5;
  display: none;
}
.photoalbum-overlay .image--loading .image__sizer > span {
  display: block;
}
.photoalbum-overlay .image > .wh-video, .photoalbum-overlay .image iframe {
  position: absolute !important; /* overwrite .wh-video css from publisher:video.css  */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.photoalbum-overlay .image .video-playbtn {
  cursor: pointer;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent url(../../web/img/play.png) 50% 50% no-repeat;
  background-size: 15%;
  left: 0;
  top: 0;
  opacity: 0.75;
  transition: opacity 0.3s;
  z-index: 1;
}
.photoalbum-overlay .image:hover .video-playbtn {
  opacity: 0.9;
}
.photoalbum-overlay .image > .title {
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 15px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
}
@media (max-width: 600px) {
  .photoalbum-overlay .next, .photoalbum-overlay .previous {
    justify-content: center;
  }
}