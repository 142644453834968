.widget-news a {
  text-decoration: none;
}
.widget-news--withhighlight {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.widget-news__highlight {
  flex: none;
  width: 350px;
  align-self: flex-start;
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #E7E9E4;
  margin-top: 10px;
  position: relative;
  padding-bottom: 90px;
}
.widget-news__highlight + .widget-news__recent .widget-news__more {
  position: absolute;
  bottom: 32px;
  left: 30px;
}
.widget-news__highlight h2, .widget-news__highlight p {
  padding: 10px 0 0;
  margin-bottom: 0;
}
.widget-news__highlight__image {
  flex: none;
  border-radius: 2px 2px 0 0;
  width: 100%;
}
.widget-news__highlight__image > img {
  width: 100%;
  height: auto;
  border-radius: 2px 2px 0 0;
}
.widget-news__highlight__text {
  padding: 0 20px;
}
.widget-news__highlight .button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  max-width: calc(100% - 40px);
}
.widget-news__highlight + .widget-news__recent {
  padding-left: 30px;
  position: relative;
  padding-bottom: 70px;
}
@media (max-width: 1000px) {
  .widget-news__highlight + .widget-news__recent .contentlist__item__image {
    display: none;
  }
}
@media (max-width: 810px) {
  .widget-news__highlight {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
  }
  .widget-news__highlight + .widget-news__recent {
    padding-left: 0;
  }
  .widget-news__highlight + .widget-news__recent .contentlist__item__image {
    display: block;
  }
  .widget-news__highlight + .widget-news__recent .widget-news__more {
    left: 0;
  }
  .widget-news__highlight__image {
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    max-width: 50%;
    border-radius: 2px 0 0 0;
  }
  .widget-news__highlight__image > img {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    left: -9999px;
    right: -9999px;
    margin: 0 auto;
    border-radius: 2px 0 0 2px;
  }
  .widget-news__highlight__text {
    max-width: 50%;
  }
}
@media (max-width: 700px) {
  .widget-news__highlight__image {
    display: none;
  }
  .widget-news__highlight__text {
    max-width: 100%;
  }
}
@media (max-width: 600px) {
  .widget-news__highlight + .widget-news__recent .contentlist__item__image {
    display: none;
  }
}
.widget-news__recent h2 {
  font-size: 42px;
  font-weight: normal;
}
.widget-news__more {
  color: #283477;
  display: inline-block;
  white-space: nowrap;
  transition: color 0.2s;
  line-height: 22px;
}
.widget-news__more:hover {
  color: #4078C5;
}
.widget-news__more .arrow {
  font-size: 20px;
  position: relative;
  top: 1px;
  vertical-align: top;
  padding-right: 10px;
}
@media (max-width: 810px) {
  .widget-news--withhighlight {
    display: block;
  }
}