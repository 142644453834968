@import "/.publisher/sd/kiskeya/plankiskeya/fonts/faustina/faustina.css";
@import "/.publisher/sd/kiskeya/plankiskeya/fonts/opensans/opensans.css";
@import "/.publisher/sd/kiskeya/plankiskeya/fonts/fontawesome/css/all.min.css";

html
{
  font: 16px 'Open Sans', sans-serif, Arial;
  color: #000000;
}

h1, h2, h3
{
  font-family: 'Faustina', serif, Arial;
  line-height: 120%;
  color: #283477;
}
h1
{
  font-size: 42px;
  line-height: 115%;
  text-align: center;
  margin-bottom: 20px;
}
h2
{
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 120%;
}
h3
{
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 140%;
}
h4
{
  font-size: inherit;
  margin-bottom: 5px;
}

p a, ul a, ol a, table a, form a
{
  color: #1a7dd7;
}

p, .widget
{
  margin-bottom: 30px;
  line-height: 175%;
}

p.intro
{
  text-align: center;
  font: 400 21px/160% 'Faustina', serif, Arial;
}

p.caption
{
  font-size: 15px;
  line-height: 145%;
  color: #6a6a6a;
}

@media(max-width: 768px)
{
  h1
  {
    font-size: 36px;
  }
}

@media()
{
  h1
  {
    font-size: 7.5vw;
  }
}
@media(max-width: 560px)
{
  h1
  {
    font-size: 7.5vw;
  }
}
@media(max-width: 448px)
{
  h2
  {
    font-size: 6.25vw;
  }
}
@media(max-width: 356px)
{
  h3
  {
    font-size: 5.625vw;
  }
}
@media(max-width: 356px)
{
  p.intro
  {
    font-size: 17px;
  }

  p.caption
  {
    font-size: 13px;
  }
}

@media(max-width: 600px)
{
  html
  {
    font-size: 15px;
  }
}

@media(max-width: 320px)
{
  h1
  {
    font-size: 24px;
  }
  h2
  {
    font-size: 20px;
  }
  h3
  {
    font-size: 18px;
  }
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
, ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  line-height: 160%;
  list-style-type: none;
}
  ul.unordered
, ol.ordered
{
  margin-bottom: 20px;
}
  article * ul.unordered
, article * ol.ordered
, .wh-rtd-editor ul.unordered
, .wh-rtd-editor ol.ordered
{
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}
  ul.unordered li
, ol.ordered li
{
  position: relative;
  padding: 0 0 10px 20px;
  min-height: 15px;
}
  ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
  ul.unordered li::before
, ol.ordered li::before
{
  color: #000;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
  ul.unordered > li
, ol.ordered ul > li
, ul.unordered ul > li
{
  padding-left: 15px;
  padding-bottom: 5px;
  min-height: 15px;
}
  ul.unordered > li::before
, ol.ordered ul > li::before
, ul.unordered ul > li::before
{
  padding-top: 2px;
  content: '•';
  text-align: center;
}
  ol.ordered
, ul.unordered ol
, ol.ordered ol
{
  counter-reset: li;
}
  ol.ordered > li:before
, ul.unordered ol > li:before
, ol.ordered ol > li:before
{
  content: counter(li) ".";
  counter-increment: li;
}



/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
article > .wh-rtd__tablewrap
{
  max-width: 790px;
}

.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}
