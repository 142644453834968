html {
  background-color: #ffffff;
  min-height: 100%;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
}

body {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  width: 100vw;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
html.mobilelayout.showmobilemenu body, html.hidescroll body {
  overflow: hidden;
}

header, footer {
  flex: none;
}

html.beforeload * {
  transition-duration: 0s !important;
}

main {
  flex: 1 0 auto; /* vertical (column) outfill */
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

article > * {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
}
@media (max-width: 600px) {
  article > * {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.centercontent,
article > .xwidecontent,
.fullwidth > .xwidecontent {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
}
@media (max-width: 600px) {
  .centercontent,
  article > .xwidecontent,
  .fullwidth > .xwidecontent {
    padding-left: 20px;
    padding-right: 20px;
  }
}

article > .fullwidth {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.fullwidth--bglightergrey {
  background-color: #f3f3f1;
}

.fullwidth--bggreen {
  background-color: #289C5C;
}

.wh-anchor {
  position: absolute;
}

.pagetitlebar {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.pagetitlebar h1, .pagetitlebar h2 {
  color: #fff;
  margin-bottom: 0;
}
.pagetitlebar + p {
  margin-top: 30px;
}

p.intro, h1 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 980px;
}
@media (max-width: 600px) {
  p.intro, h1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.wh-rtd__anchor {
  display: inline-block;
  position: absolute;
  transform: translateY(-102px);
}
html.mobilelayout .wh-rtd__anchor {
  transform: translateY(-42px);
}

.pagedate {
  color: #1A7DD7;
  font: 400 21px/140% "Faustina", serif, Arial;
  text-align: center;
}

.pageimage .pagedate {
  color: #fff;
}

.pagecontentlist::before {
  content: "";
  display: block;
  border-top: 1px solid #C5C5C5;
}

/* contentlist */
.contentlist {
  list-style-type: none;
  margin: 30px 0 15px;
  padding: 0;
}
.contentlist a {
  color: inherit;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.contentlist a::before {
  content: "";
  display: block;
  position: absolute;
  top: -15px;
  right: -30px;
  bottom: -15px;
  left: -30px;
  background-color: #F3F3F1;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: -1;
  border-radius: 3px;
}
.contentlist a:hover::before {
  opacity: 1;
}
.contentlist__item + .contentlist__item {
  margin-top: 30px;
}
.contentlist__item__text > * {
  display: block;
  line-height: 28px;
}
.contentlist__item__text__date {
  color: #4078C5;
  margin-top: -8px;
  vertical-align: top;
}
.contentlist__item__text__title {
  color: #283477;
  font: bold 20px/28px "Faustina", serif, Arial;
}
.contentlist__item__text__description {
  padding-top: 10px;
}
.contentlist__item__image {
  display: block;
  margin-left: auto;
  padding-left: 20px;
  flex: none;
  width: 180px;
}
@media (max-width: 600px) {
  .contentlist__item a::before {
    right: -20px;
    left: -20px;
  }
  .contentlist__item__image {
    display: none;
  }
}

/* page image */
.pageimage {
  margin-top: -122px; /* height header + main paddingTop */
  margin-bottom: 20px;
  overflow: hidden;
  color: #fff;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 56.25vw;
  max-height: 500px;
}
.pageimage--window {
  height: calc(100vh - 46px);
  max-height: none;
}
.pageimage--small {
  max-height: 220px;
}
html.mobilelayout .pageimage--window {
  height: calc(100vh - 44px);
}
html.mobilelayout .pageimage {
  margin-top: -22px;
}
.pageimage .scrollindicator {
  display: inline-block;
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -13px;
  font-size: 80px;
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  cursor: pointer;
}
html.mobilelayout .pageimage .scrollindicator {
  bottom: -35px;
}
.pageimage .content {
  position: absolute;
  top: 102px;
  left: 0;
  right: 0;
  bottom: 30px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
}
html.mobilelayout .pageimage .content {
  justify-content: flex-end;
  top: 30px;
}
.pageimage--small .content {
  top: auto;
  bottom: 0;
  justify-content: flex-end;
}
.pageimage--window .content, html.mobilelayout .pageimage--window .content {
  justify-content: center;
}
.pageimage h1 {
  color: inherit;
}
.pageimage h2 {
  font-size: 42px;
  line-height: 115%;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 790px;
}
@media (max-width: 600px) {
  .pageimage h2 {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.pageimage p {
  font: 400 21px/140% "Faustina", serif, Arial;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 980px;
}
@media (max-width: 600px) {
  .pageimage p {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.pageimage h1, .pageimage h2, .pageimage p, .pageimage a {
  text-align: center;
  color: inherit;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

/* horizontal perfect slider scrollbar */
.horizontal-scroller.nativescroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.horizontal-scroller.ps-container .ps-scrollbar-x-rail {
  opacity: 1;
  display: none;
  background-color: rgba(255, 255, 255, 0.3) !important;
  position: relative;
  height: 5px;
  border-radius: 3px;
}
.horizontal-scroller.ps-container .ps-scrollbar-x-rail::before {
  content: "";
  background-color: transparent;
  height: 35px;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
}
.horizontal-scroller.ps-container .ps-scrollbar-x-rail > .ps-scrollbar-x {
  height: 5px !important;
  border-radius: 3px;
  background-color: #fff !important;
  bottom: 0;
}
.horizontal-scroller.ps-container .ps-scrollbar-x-rail > .ps-scrollbar-x::before {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  right: 0;
  top: -15px;
  left: 0;
  right: 0;
  height: 35px;
}
.horizontal-scroller.ps-container .ps-scrollbar-y-rail {
  display: none;
}
.horizontal-scroller.ps-active-x .ps-scrollbar-x-rail {
  display: block;
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}