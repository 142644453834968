/* path */
.pathnav {
  color: #7C7C7C;
  margin-bottom: 15px;
  flex: none;
}
.pathnav ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 15px;
}
.pathnav ol.overflow {
  text-overflow: initial;
}
@media (max-width: 700px) {
  .pathnav ol {
    font-size: 13px;
  }
}
.pathnav li {
  display: inline;
}
.pathnav li.hide {
  display: none;
}
.pathnav li + li::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  font-weight: 300;
  font-size: 16px;
  position: relative;
  top: 2px;
  vertical-align: bottom;
  padding: 0 7px;
  display: inline-block;
}
.pathnav ol.shorten > li:first-child::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f105  ...";
  font-weight: 300;
  font-size: 16px;
  position: relative;
  top: 2px;
  vertical-align: bottom;
  padding-left: 7px;
  display: inline-block;
}
.pathnav a {
  color: inherit;
  text-decoration: none;
}
.pathnav a:hover {
  text-decoration: underline;
}

footer {
  background: #283477 url(../web/img/bgfooter.png) 80% 50% no-repeat;
  background-size: auto 100%;
  min-height: 100px;
  color: #fff;
  font-size: 13px;
  padding-top: 60px;
}
footer a {
  color: inherit;
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}
footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
footer p {
  margin: 0;
  padding-bottom: 10px;
}
footer .columns {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  display: flex;
  padding-bottom: 50px;
  padding-left: 0;
}
@media (max-width: 600px) {
  footer .columns {
    padding-left: 20px;
    padding-right: 20px;
  }
}
footer .columns .col {
  flex: 1 1 100%;
  padding-left: 30px;
  max-width: 25%;
  min-width: 25%;
}
footer .columns .col__title {
  font: 700 16px/160% "Faustina", serif, Arial;
  padding-bottom: 10px;
  position: relative;
}
footer .columns .col:last-child {
  text-align: right;
  max-width: 100%;
  min-width: 0;
}
footer .columns .col .toggle {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-top: -16px;
}
footer .columns .col .toggle .hide {
  display: none;
}
footer .columns .col.active .toggle .show {
  display: none;
}
footer .columns .col.active .toggle .hide {
  display: inline-block;
}
footer .footerbottombar {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  min-height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}
footer .footerbottombar .footernav {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  align-items: center;
  min-height: 60px;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 600px) {
  footer .footerbottombar .footernav {
    padding-left: 20px;
    padding-right: 20px;
  }
}
footer .footerbottombar .footernav li {
  display: inline-block;
  white-space: nowrap;
  line-height: 20px;
}
footer .footerbottombar .footernav li + li {
  margin-left: 30px;
}
footer .footerbottombar .footernav li.spacer {
  margin-left: 0;
  flex: 1 1 100%;
}
footer .footerbottombar .footernav .webhare img {
  margin-left: 5px;
  position: relative;
  top: -2px;
  vertical-align: middle;
}
footer .footerbottombar .footernav .icon {
  font-size: 20px;
  display: inline-block;
  padding-right: 10px;
  vertical-align: top;
}
@media (max-width: 700px) {
  footer .footerbottombar .footernav {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  footer .footerbottombar .footernav li + li {
    margin-left: 15px;
  }
  footer .footerbottombar .footernav li.spacer {
    display: none;
  }
  footer .footerbottombar .footernav li:last-child {
    flex-basis: 100%;
    margin-left: 0;
    text-align: left;
  }
}
@media (max-width: 800px) {
  footer {
    padding-top: 20px;
  }
  footer .columns {
    flex-wrap: wrap;
    padding-bottom: 15px;
  }
  footer .columns .col {
    max-width: 33.33%;
    min-width: 33.33%;
  }
  footer .columns .col:last-child {
    min-width: 100%;
    max-width: 100%;
    text-align: left;
    padding-left: calc(66.66% + 30px);
  }
}
@media (max-width: 650px) {
  footer {
    background-image: none;
  }
  footer .columns {
    flex-wrap: wrap;
    padding-bottom: 15px;
  }
  footer .columns .col {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 10px;
  }
  footer .columns .col .toggle {
    display: block;
  }
  footer .columns .col--foldable .col__title {
    cursor: pointer;
  }
  footer .columns .col--foldable .col__text {
    overflow: hidden;
    max-height: 0;
    transition: max-height 300ms;
  }
  footer .columns .col--foldable.active .col__text {
    max-height: none;
  }
  footer .columns .col:last-child {
    padding-left: 30px;
  }
  footer .footerbottombar {
    padding-top: 15px;
    /*
          .footernav
          {
            margin-left: -15px;
            li
            {
              margin-left: 0;
              padding-left: 15px;
            }
          }
    */
  }
  footer .footerbottombar > div {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 600px) {
  footer .columns .col,
  footer .columns .col:last-child {
    padding-left: 0;
  }
}