header {
  color: #fff;
  height: 148px;
  font-family: "Faustina", serif, Arial;
  z-index: 10;
  position: relative;
  transition: color 0.3s;
}
html.mobilelayout header {
  height: 44px;
}
header a {
  color: inherit;
  text-decoration: none;
}
header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header *[tabindex]:focus {
  outline: none;
}
header .languagenav {
  display: inline-block;
  font-size: 15px;
}
header .languagenav > li {
  display: inline-block;
}
header .languagenav > li + li::before {
  display: inline-block;
  content: "|";
  padding: 0 7px;
}
header .languagenav a {
  opacity: 0.7;
  transition: opacity 0.2s;
}
header .languagenav li.active a,
header .languagenav a:hover {
  opacity: 1;
}
@media (max-width: 800px) {
  header .languagenav {
    display: none;
  }
}
header .topbar {
  background-color: #283477;
  height: 46px;
  font-size: 15px;
  white-space: nowrap;
  transition: background-color 0.2s;
}
html.mobilelayout.showmobilemenu header .topbar {
  background-color: #1B2453;
}
html.mobilelayout.showmobilemenu header .topbar .languagenav, html.mobilelayout.showmobilemenu header .topbar .donate-button {
  display: none;
}
header .topbar .logo--color + .logo--white {
  display: none;
}
html.donatepage header .topbar {
  background-color: #FFD152;
  color: #CA243A;
}
html.donatepage header .topbar .languagenav {
  display: none;
}
html.mobilelayout.showmobilemenu.donatepage header .topbar {
  color: #fff;
}
html.mobilelayout.showmobilemenu.donatepage header .topbar .logo--color {
  display: none;
}
html.mobilelayout.showmobilemenu.donatepage header .topbar .logo--color + .logo--white {
  display: block;
}
header .topbar__nav {
  padding-left: 15px;
}
header .topbar__nav > * {
  vertical-align: bottom;
  line-height: 44px;
}
header .topbar .menutoggle {
  display: none;
  font-size: 20px;
  flex: none;
  width: 46px;
  text-align: center;
  height: 44px;
  cursor: pointer;
}
header .topbar .menutoggle .hide {
  font-size: 23px;
}
header .topbar .menutoggle .show, html.showmobilemenu header .topbar .menutoggle .hide {
  display: inline-block;
}
header .topbar .menutoggle .hide, html.showmobilemenu header .topbar .menutoggle .show {
  display: none;
}
header .topbar > div {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
@media (max-width: 600px) {
  header .topbar > div {
    padding-left: 20px;
    padding-right: 20px;
  }
}
header .topbar .sitetitle {
  flex: none;
}
header .topbar .sitetitle .logo {
  display: none;
}
html.mobilelayout header .topbar {
  height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
html.mobilelayout header .topbar > div {
  padding-right: 0;
}
html.mobilelayout header .topbar .sitetitle {
  margin-left: -11px;
}
html.mobilelayout header .topbar .sitetitle > .logo {
  display: inline-block;
}
html.mobilelayout header .topbar .sitetitle > .title {
  display: none;
}
html.mobilelayout header .topbar .menutoggle {
  display: inline-block;
}
header .topbar .donate-button {
  display: inline-block;
  flex: none;
  position: relative;
  background-color: #CA243A;
  min-width: 135px;
  height: 46px;
  padding: 9px 15px 0;
  margin-left: 25px;
  width: 189px;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
}
@media (max-width: 420px) {
  header .topbar .donate-button {
    margin-left: 10px;
    padding-left: 10px;
  }
}
header .topbar .donate-button .icon {
  font-size: 19px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
}
header .topbar .donate-button .text {
  opacity: 1;
  transition: opacity 0.2s;
}
html.mobilelayout header .topbar .donate-button {
  height: 44px;
  padding-top: 7px;
}
html.mobilelayout header .topbar .donate-button .icon {
  font-weight: 400;
}
header .topbar .donate-button:hover, html.donatepage header .topbar .donate-button {
  color: #CA243A;
  background-color: #FFD152;
}
html.donatepage header .topbar .donate-button .text {
  opacity: 0;
}
@media (max-width: 1170px) {
  header .topbar .donate-button {
    min-width: calc(12vw + 30px);
  }
}
@media (max-width: 600px) {
  header .topbar .donate-button {
    width: auto;
    padding-right: 40px;
    min-width: 0;
    font-size: 18px;
    line-height: 28px;
    font-weight: normal;
  }
}
header .mainnav {
  height: 102px;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  line-height: 30px;
  position: relative;
  transition: background-color 0.3s;
  z-index: 1; /* on top of topsearch */
}
html.coloredmenu.searchfamilypage header .mainnav, html.coloredmenu.loginpage header .mainnav {
  background-color: #026A16;
  color: #fff;
}
html.coloredmenu.searchfamilypage header .mainnav li.active > a,
html.coloredmenu.searchfamilypage header .mainnav li:hover > a, html.coloredmenu.loginpage header .mainnav li.active > a,
html.coloredmenu.loginpage header .mainnav li:hover > a {
  border-color: #fff;
}
html.coloredmenu.searchfamilypage header .mainnav .logo--color, html.coloredmenu.loginpage header .mainnav .logo--color {
  display: none;
}
html.mobilelayout.showmobilemenu header .mainnav {
  display: block;
}
html.mobilelayout.showmobilemenu header .mainnav .logo {
  display: none;
}
html.fixedmenu header .mainnav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
html.coloredmenu header .mainnav {
  background-color: #f3f3f1;
  color: #283477;
}
html.registrationpage.coloredmenu header .mainnav {
  background-color: #026A16;
  color: #fff;
}
html.registrationpage.coloredmenu header .mainnav .logo--color {
  display: none;
}
html.mobilelayout header .mainnav {
  display: none;
  position: fixed;
  top: 44px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #283477;
  color: #fff;
}
header .mainnav .togglesearch {
  transition: text-shadow 0.2s;
  cursor: pointer;
}
header .mainnav .togglesearch:hover {
  text-shadow: 0 0 1px #fff;
}
header .mainnav .languagenav {
  display: none;
  margin: 40px 30px 20px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
html.mobilelayout header .mainnav .languagenav {
  display: block;
}
@media (max-width: 600px) {
  header .mainnav .languagenav {
    margin-left: 20px;
    margin-right: 20px;
  }
}
header .mainnav > div {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1170px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 600px) {
  header .mainnav > div {
    padding-left: 20px;
    padding-right: 20px;
  }
}
header .mainnav > div > ul {
  white-space: nowrap;
}
header .mainnav > div > ul > li {
  display: inline-block;
  margin-left: 30px;
  position: relative;
  padding-bottom: 10px;
}
header .mainnav > div > ul > li > .togglechildren {
  position: absolute;
  right: 0;
  top: 7px;
  font-size: 16px;
  display: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}
header .mainnav > div > ul > li > a {
  transition: text-shadow 0.2s, border-color 0.2s;
  border-bottom: 1px solid transparent;
}
header .mainnav > div > ul > li:hover > a, header .mainnav > div > ul > li.active > a {
  border-color: #fff;
  text-shadow: 0 0 1px #fff;
}
html.coloredmenu header .mainnav > div > ul > li:hover > a, html.coloredmenu header .mainnav > div > ul > li.active > a {
  border-color: #283477;
  text-shadow: 0 0 1px #283477;
}
html.searchfamilypage--details header .mainnav > div > ul > li:hover > a, html.registrationpage header .mainnav > div > ul > li:hover > a, html.searchfamilypage--details header .mainnav > div > ul > li.active > a, html.registrationpage header .mainnav > div > ul > li.active > a {
  border-color: #fff;
  text-shadow: 0 0 1px #fff;
}
header .mainnav > div > ul > li > ul {
  font-size: 18px;
  line-height: 24px;
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 12px 7px rgba(0, 0, 0, 0.1);
  border: 1px solid #E8E9E6;
  width: 245px;
  border-radius: 3px;
  background-color: #fff;
  white-space: normal;
  padding: 15px 0 20px;
}
header .mainnav > div > ul > li > ul li {
  display: block;
}
header .mainnav > div > ul > li > ul a {
  display: block;
  padding: 7px 20px;
  color: #000;
  transition: color 0.2s, background-color 0.2s;
  text-shadow: none;
}
header .mainnav > div > ul > li > ul li.active > a,
header .mainnav > div > ul > li > ul a:hover {
  background-color: #f3f3f1;
  color: #1A7DD7;
}
header .mainnav > div > ul > li:hover > ul, header .mainnav > div > ul > li > a:focus + ul {
  display: block;
}
header .mainnav > div > ul > li.search {
  margin-left: 60px;
  padding-right: 10px;
}
html.mobilelayout header .mainnav > div {
  display: block;
  font-size: 18px;
  line-height: 22px;
  height: auto;
}
html.mobilelayout header .mainnav > div > ul {
  white-space: normal;
  padding-top: 75px;
  max-width: 370px;
}
html.mobilelayout header .mainnav > div > ul > li {
  display: block;
  margin-left: 0;
  padding-bottom: 10px;
  padding-right: 25px;
}
html.mobilelayout header .mainnav > div > ul > li > .togglechildren--show, html.mobilelayout header .mainnav > div > ul > li.showchildren > .togglechildren--hide, html.mobilelayout header .mainnav > div > ul > li:hover > .togglechildren--hide, html.mobilelayout header .mainnav > div > ul > li > a:focus ~ .togglechildren--hide {
  display: block;
}
html.mobilelayout header .mainnav > div > ul > li.showchildren > .togglechildren--show, html.mobilelayout header .mainnav > div > ul > li:hover > .togglechildren--show, html.mobilelayout header .mainnav > div > ul > li > a:focus ~ .togglechildren--show {
  display: none;
}
html.mobilelayout header .mainnav > div > ul > li > ul {
  display: none;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  box-shadow: none;
  border: 0 none;
  width: 100%;
  background-color: transparent;
  white-space: normal;
  padding: 0 0 0 20px;
}
html.mobilelayout header .mainnav > div > ul > li > ul a {
  display: inline-block;
  padding: 0;
  color: #fff;
  transition: border-color 0.2s;
  border-bottom: 1px solid transparent;
  text-shadow: none;
}
html.mobilelayout header .mainnav > div > ul > li > ul li {
  padding-top: 10px;
}
html.mobilelayout header .mainnav > div > ul > li > ul li.active > a,
html.mobilelayout header .mainnav > div > ul > li > ul a:hover {
  background-color: transparent;
  border-bottom-color: #fff;
}
html.mobilelayout header .mainnav > div > ul > li > a {
  font-weight: 700;
}
html.mobilelayout header .mainnav > div > ul > li:hover > ul, html.mobilelayout header .mainnav > div > ul > li > a:focus + ul, html.mobilelayout header .mainnav > div > ul > li.showchildren > ul {
  display: block;
}
html.mobilelayout header .mainnav > div > ul > li.search {
  display: none;
}
header .mainnav .logo {
  display: inline-block;
  width: 205px;
  height: 70px;
  flex: none;
  position: relative;
  overflow: hidden;
}
header .mainnav .logo > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
}
header .mainnav .logo--color {
  opacity: 0;
}
html.coloredmenu header .mainnav .logo--color {
  opacity: 1;
}
header .mainnav .logo--mobile {
  display: none;
}