@charset "UTF-8";
html.searchfamilypage--list {
  color: #fff;
  background-color: #289C5C;
}
html.searchfamilypage--list article h1 {
  color: #fff;
}

html.searchfamilypage--details .searchfamily-detailsheader {
  background-color: #289C5C;
}
html.searchfamilypage--details article > * {
  max-width: 980px;
}
html.searchfamilypage--details article > *.fullwidth {
  max-width: 100%;
}
html.searchfamilypage--details .searchfamily-detailsimage {
  position: absolute;
  width: 162px;
  left: 50%;
  top: 30px;
  padding-left: 0;
  padding-right: 0;
  margin-left: -460px;
}
html.searchfamilypage--details .searchfamily-detailsimage .image {
  width: 162px;
  margin: 0 auto;
  background-color: #ccc;
  max-width: 100%;
  position: relative;
}
html.searchfamilypage--details .searchfamily-detailsimage .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
html.searchfamilypage--details .searchfamily-detailsimage .image::after {
  padding-top: 133.333%;
  content: "";
  display: block;
}
html.searchfamilypage--details .searchfamily-detailsimage__matched {
  font: 700 16px/26px "Faustina", serif, Arial;
  color: #fff;
  position: absolute;
  right: -20px;
  bottom: -20px;
  line-height: 26px;
  padding-top: 5px;
  background-color: #ED4C10;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
}
html.searchfamilypage--details .searchfamily-detailsimage__matched i {
  font-size: 36px;
}
@media (max-width: 980px) {
  html.searchfamilypage--details .searchfamily-detailsimage {
    margin-left: 0;
    left: 30px;
  }
}
html.searchfamilypage--details h1 {
  padding-top: 40px;
  padding-bottom: 40px;
  color: #fff;
  text-align: left;
  margin-bottom: 0;
  padding-left: 220px; /* space for image */
}
html.searchfamilypage--details h1 small {
  display: inline-block;
  padding-right: 10px;
  font-weight: normal;
}
@media (max-width: 600px) {
  html.searchfamilypage--details .searchfamily-detailsimage {
    margin: -110px auto 20px;
    left: auto;
    position: relative;
    width: 100%;
  }
  html.searchfamilypage--details .searchfamily-detailsimage .image {
    max-width: 33.33vw;
  }
  html.searchfamilypage--details h1 {
    text-align: center;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 100px;
  }
}
html.searchfamilypage--details main {
  padding-top: 0;
}
html.searchfamilypage--details .searchfamily-detailsmeta {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 980px;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 220px; /* space for image */
  min-height: 160px;
  line-height: 175%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 600px) {
  html.searchfamilypage--details .searchfamily-detailsmeta {
    padding-left: 20px;
    padding-right: 20px;
  }
}
html.searchfamilypage--details .searchfamily-detailsmeta__contact {
  align-self: flex-end;
  margin-left: 30px;
}
@media (max-width: 750px) {
  html.searchfamilypage--details .searchfamily-detailsmeta {
    display: block;
  }
  html.searchfamilypage--details .searchfamily-detailsmeta__contact {
    margin-top: 20px;
    margin-left: 0;
  }
}
@media (max-width: 600px) {
  html.searchfamilypage--details .searchfamily-detailsmeta {
    padding-bottom: 20px;
    min-height: 0;
  }
}
html.searchfamilypage--details .searchfamily-detailsmeta a {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  color: #1B2453;
  text-decoration: none;
}
html.searchfamilypage--details .searchfamily-detailsmeta a:hover {
  text-decoration: underline;
}
html.searchfamilypage--details .searchfamily-detailsmeta a i {
  font-size: 20px;
  position: absolute;
  top: 4px;
  left: 0;
}
html.searchfamilypage--details .searchfamily-detailsfor {
  background-color: #F3F3F1;
  margin-bottom: 30px;
}
html.searchfamilypage--details .searchfamily-detailsfor .widget, html.searchfamilypage--details .searchfamily-detailsfor dl {
  margin-bottom: 0;
}
html.searchfamilypage--details .searchfamily-detailsfor__name, html.searchfamilypage--details .searchfamily-detailsfor__data {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 980px;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 600px) {
  html.searchfamilypage--details .searchfamily-detailsfor__name, html.searchfamilypage--details .searchfamily-detailsfor__data {
    padding-left: 20px;
    padding-right: 20px;
  }
}
html.searchfamilypage--details .searchfamily-detailsfor__name h2, html.searchfamilypage--details .searchfamily-detailsfor__data h2 {
  margin-bottom: 0;
}
html.searchfamilypage--details .searchfamily-detailsfor h2 > span {
  font-weight: normal;
}
html.searchfamilypage--details h3 {
  margin-bottom: 20px;
}
html.searchfamilypage--details dl {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 30px;
}
html.searchfamilypage--details dl dt, html.searchfamilypage--details dl dd {
  margin: 0;
  padding: 0;
  display: block;
  padding-bottom: 5px;
}
html.searchfamilypage--details dl dt {
  flex: none;
  width: 190px;
  padding-right: 5px;
}
html.searchfamilypage--details dl dt::after {
  content: ":";
}
html.searchfamilypage--details dl dd {
  flex: 1 1 100%;
  max-width: calc(100% - 200px);
}

.searchfamily-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.searchfamily-header .resultscount {
  flex: 1 1 33.333%;
  max-width: 33.333%;
}
.searchfamily-header form {
  flex: 1 1 66.666%;
  max-width: 66.666%;
  display: flex;
  align-items: inherit;
  justify-content: flex-end;
}
.searchfamily-header .searchfilter-wrapper {
  display: inline-block;
  white-space: nowrap;
  margin-left: 20px;
  align-items: inherit;
}
.searchfamily-header .searchfilter-wrapper > .wh-form__pulldown-styled {
  margin-left: 20px;
  display: inline-block;
}
.searchfamily-header .searchfilter-wrapper > .wh-form__pulldown-styled > select {
  background-color: #289C5C;
  border-color: #fff;
}
.searchfamily-header .searchfilter-wrapper > .wh-form__pulldown-styled > select:focus {
  border-color: #fff;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.15);
}
.searchfamily-header .searchfilter-wrapper > .wh-form__pulldown-styled .arrow {
  color: #fff;
}
.searchfamily-header .searchfilter-wrapper > .wh-form__pulldown-styled::after {
  display: block;
  content: "";
  width: 25px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 28px;
  top: 3px;
  bottom: 3px;
  pointer-events: none;
  background: -moz-linear-gradient(left, rgba(40, 156, 92, 0) 0%, rgb(40, 156, 92) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(40, 156, 92, 0) 0%, rgb(40, 156, 92) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(40, 156, 92, 0) 0%, rgb(40, 156, 92) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00289C5C", endColorstr="#289C5C",GradientType=1 ); /* IE6-9 */
}
@media (max-width: 750px) {
  .searchfamily-header {
    flex-flow: column;
    align-items: flex-start;
  }
  .searchfamily-header .resultscount {
    flex: none;
    max-width: 100%;
    margin-bottom: 15px;
  }
  .searchfamily-header .searchfilter-wrapper {
    margin-left: 0;
  }
  .searchfamily-header form {
    flex: none;
    max-width: 100%;
  }
}

.searchfamily-results--noresults > ul::after {
  display: flex;
  content: "No results";
  flex: none;
  width: 25%;
  font-weight: 700;
  padding-top: 3px;
  padding-left: 30px;
  font-style: italic;
  text-align: center;
}
html[lang=fr] .searchfamily-results--noresults > ul::after {
  content: "Pas de résultats";
}
html[lang=nl] .searchfamily-results--noresults > ul::after {
  content: "Geen resultaten";
}
@media (max-width: 750px) {
  .searchfamily-results--noresults > ul::after {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .searchfamily-results--noresults > ul::after {
    width: 100%;
  }
}
.searchfamily-results__intro {
  font-size: 15px;
}
.searchfamily-results__intro__inner {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 20px 15px;
  border-radius: 3px;
  background-color: #026A16;
}
.searchfamily-results__intro__inner p {
  flex: 1;
}
.searchfamily-results__intro__inner .button {
  flex: none;
}
.searchfamily-results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-left: -30px;
}
.searchfamily-results ul > li {
  flex: 1 1 25%;
  max-width: 25%;
  padding-left: 30px;
  padding-bottom: 30px;
}
@media (max-width: 1000px) {
  .searchfamily-results ul > li {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
}
@media (max-width: 750px) {
  .searchfamily-results ul > li {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (max-width: 500px) {
  .searchfamily-results ul {
    display: block;
  }
  .searchfamily-results ul > li {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.searchfamily-results .person a {
  color: inherit;
  text-decoration: none;
  display: block;
  height: 100%;
  border: 1px solid #FFD152;
  position: relative;
  font-family: "Faustina", serif, Arial;
}
.searchfamily-results .person a > * {
  display: block;
}
.searchfamily-results .person__name, .searchfamily-results .person__searchfor {
  max-width: 100%;
  background-color: #FFD152;
  color: #000;
  opacity: 0.9;
  white-space: nowrap;
  height: 40px;
  padding: 10px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchfamily-results .person__name {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
}
.searchfamily-results .person__image {
  position: relative;
  overflow: hidden;
  z-index: -1;
}
.searchfamily-results .person__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.searchfamily-results .person__image::after {
  display: block;
  padding-top: 133.33%;
  content: "";
}
.searchfamily-results .person .person__matched {
  position: absolute;
  right: 14px;
  bottom: 54px;
  line-height: 26px;
  padding-top: 5px;
  background-color: #ED4C10;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  font-weight: 700;
}
.searchfamily-results .person .person__matched i {
  font-size: 36px;
}
.searchfamily-results .person__searchfor {
  position: relative;
  margin-top: -40px;
  padding-right: 40px;
}
.searchfamily-results .person__searchfor .arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 20px;
  margin-top: -9px;
}
.searchfamily-results .person__meta {
  padding: 5px 10px 10px;
  align-self: flex-end;
  font-size: 14px;
  margin: 0;
}
.searchfamily-results .person__meta dt, .searchfamily-results .person__meta dd {
  margin: 0;
  padding: 0;
  display: block;
}
.searchfamily-results .person__meta dd + dt {
  margin-top: 5px;
}
.searchfamily-results .person__meta dt {
  color: #026A16;
  text-transform: uppercase;
  padding-right: 5px;
}
.searchfamily-results .person__meta dt::after {
  content: ":";
}