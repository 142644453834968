.widget-slideshow h2 {
  margin-bottom: 15px;
}
.widget-slideshow__container {
  position: relative;
  outline: none;
}
.widget-slideshow__container__slides {
  position: relative;
  outline: none;
}
.widget-slideshow__container__slides::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-slideshow__container__slides__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;
}
.widget-slideshow__container__slides__slide.active {
  opacity: 1;
  z-index: 1;
}
.widget-slideshow__container__slides__slide img {
  vertical-align: bottom;
  width: 100%;
  height: 100%;
}
.widget-slideshow__container__slides__slide__title {
  color: #fff;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 15px;
  line-height: 22px;
  padding: 15px 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  min-height: 50%;
  max-height: 100%;
}
.widget-slideshow__container__slides__slide__video {
  position: absolute !important; /* overwrite .wh-video css from publisher:video.css  */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-slideshow__container__slides__slide__video::after {
  content: "";
  cursor: pointer;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent url(../../web/img/play.png) 50% 50% no-repeat;
  background-size: 15%;
  left: 0;
  top: 0;
  opacity: 0.75;
  transition: opacity 0.3s;
}
.widget-slideshow__container__slides__slide__video:hover::after {
  opacity: 0.9;
}
.widget-slideshow__container__slides__slide__video.wh-video::after {
  display: none;
}
.widget-slideshow__container__slides__slide__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-slideshow__container__jumpbuttons {
  text-align: center;
  padding: 7px 0;
}
.widget-slideshow__container__jumpbuttons > span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #D8D8D8;
  border-radius: 50%;
  transition: background-color 0.3s;
  cursor: pointer;
  margin: 4px;
}
.widget-slideshow__container__jumpbuttons > span.active, .widget-slideshow__container__jumpbuttons > span:hover {
  background-color: #283477;
}
.widget-slideshow__nav {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 42px;
  z-index: 2; /* keep on top of slide */
  transition: transform 0.3s;
}
.widget-slideshow__nav--previous {
  left: 0;
}
.widget-slideshow__nav--previous:hover > * {
  transform: translateX(-3px);
}
.widget-slideshow__nav--next {
  right: 0;
}
.widget-slideshow__nav--next:hover > * {
  transform: translateX(3px);
}