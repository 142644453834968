.widget-imagecta {
  position: relative;
  min-height: 100px;
  color: #fff;
  margin-top: 60px;
}
.widget-imagecta__image {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.widget-imagecta h2 {
  color: inherit;
  margin-bottom: 20px;
}
.widget-imagecta .button {
  margin-bottom: 5px;
  min-width: 250px;
}
.widget-imagecta > .centercontent {
  display: flex;
  justify-content: flex-end;
}
.widget-imagecta__block {
  display: block;
  padding: 30px 30px 20px;
  background-color: #1A7DD7;
  max-width: calc(41.666% - 15px);
  margin-top: -30px;
  margin-bottom: 30px;
}
.widget-imagecta__block--green {
  background-color: #289C5C;
}
.widget-imagecta__block--red {
  background-color: #ED4C10;
}
@media (max-width: 1000px) {
  .widget-imagecta__block {
    max-width: calc(50% - 15px);
  }
}
@media (max-width: 768px) {
  .widget-imagecta__image {
    position: relative;
  }
  .widget-imagecta__image::before {
    padding-top: 56.25%;
    content: "";
    display: block;
  }
  .widget-imagecta > .centercontent {
    display: block;
    padding: 0;
  }
  .widget-imagecta__block {
    padding: 30px 30px 20px;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}