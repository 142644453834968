.widget-faq {
  display: block;
  list-style-type: none;
  padding: 0;
  border-top: 1px solid #C5C5C5;
}
.widget-faq__item {
  border-bottom: 1px solid #C5C5C5;
}
.widget-faq__item__toggle {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px 50px 15px 10px;
  cursor: pointer;
}
.widget-faq__item__toggle .hide, .widget-faq__item__toggle .show {
  font-size: 24px;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: 20px;
  width: 20px;
}
.widget-faq__item__toggle > .show, .widget-faq__item.active .widget-faq__item__toggle .hide {
  display: inline-block;
}
.widget-faq__item.active > .widget-faq__item__toggle .show, .widget-faq__item__toggle .hide {
  display: none;
}
.widget-faq__item__foldcontainer {
  position: relative;
  overflow: hidden;
  height: 0;
  transform: translate3d(0, 0, 0);
  transition: height 300ms;
}
.widget-faq__item__foldcontainer__text {
  padding: 5px 10px 20px;
}
.widget-faq__item__foldcontainer__text::after {
  clear: both;
  content: "";
  display: block;
}
.widget-faq__item__foldcontainer__text > *:last-child {
  margin-bottom: 0;
}
.widget-faq__item.active > .widget-faq__item__foldcontainer {
  height: auto;
}